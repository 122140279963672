import * as React from 'react';
import './headline.styles.scss';

interface props {
  text: string
}

export const Headline: React.FC<props> = ( {text}) => {
  return (
    <h1 className="text-left">{text}</h1>
  );
};