import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import { Headline } from '../_shared/components/headline.component'
import { Button } from '../_shared/components/button.component'

type Inputs = {
  governmentName: string;
  stageName: string;
  city: string;
  affiliations: string[]
};

export const AccountCreate = () => {
  const { register, handleSubmit, watch, errors } = useForm<Inputs>();
  const onSubmit = (data: any): void => console.log(data);

  console.log(watch('example')); // watch input value by passing the name of it

  return (
    <div className="form-container center">
      <Headline text="go on, tell us about yourself." />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="governmentName"
          placeholder="government name"
          ref={register({ required: true })}
        />
        {errors.governmentName && <span>This field is required</span>}
        <input
          name="stageName"
          placeholder="stage name"
          ref={register({ required: true })}
        />
        {errors.stageName && <span>This field is required</span>}
        <input
          name="city"
          placeholder="city"
          ref={register({ required: true })}
        />
        {errors.city && <span>This field is required</span>}
        <input
          name="affiliations"
          placeholder="affiliations"
          ref={register({ required: false })}
        />
        {errors.affiliations && <span>This field is required</span>}
        <textarea
          name="bragginRights"
          placeholder="braggin' rights"
          ref={register({ required: false })}
        />
        {errors.affiliations && <span>This field is required</span>}

        <input type="submit" value="sign up" className="btn-primary" />
      </form>
      <div className="text-left mt-1-2">
        <Link to={`/`}>
          <Button text="sign in" buttonStyle="text-link" />
        </Link>
      </div>
    </div>
  );
};
