import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Headline } from '../_shared/components/headline.component'


type Inputs = {
  opponent: string;
  battleName: string;
  battleDescription: string;
  battleRules: string;
  startDate: Date;
  endDate: Date;
};

export const BattleCreate = () => {
  const { register, handleSubmit, watch, errors } = useForm<Inputs>();
  const onSubmit = (data: any): void => console.log(data);

  console.log(watch('example')); // watch input value by passing the name of it

  return (
    <div className="form-container center">
      <Headline text="it's about to get serious, in here." />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="opponent"
          placeholder="opponent"
          ref={register({ required: true })}
        />
        {errors.opponent && <span>This field is required</span>}
        <input
          name="battleName"
          placeholder="battle name"
          ref={register({ required: true })}
        />
        {errors.battleName && <span>This field is required</span>}
        <input
          name="battleRules"
          placeholder="battle rules"
          ref={register({ required: true })}
        />
        {errors.battleRules && <span>This field is required</span>}
        <input
          name="battleDescription"
          placeholder="battle description"
          ref={register({ required: true })}
        />
        {errors.battleDescription && <span>This field is required</span>}
        <input
          type="date"
          name="startDate"
          placeholder="start date"
          ref={register({ required: true })}
        />
        {errors.startDate && <span>This field is required</span>}
        <input
          type="date"
          name="endDate"
          placeholder="end date"
          ref={register({ required: true })}
        />
        {errors.endDate && <span>This field is required</span>}

        <input type="submit" value="create the battle" className="btn-primary" />
      </form>
    </div>
  );
};