import * as React from 'react';
import { useHistory } from 'react-router-dom';
import './site-nav.styles.scss';

export const SiteNav: React.FC = () => {
  const history = useHistory();

  const handleNavClick = (route: string): void => {
    history.push(route);
  };

  return (
    <div className="site-nav flex-vert">
      <div className="logo-box nav-border-radius" onClick={() => handleNavClick('/')}>
        <h1>bs</h1>
        <p>battle skratch</p>
      </div>
      <div
        className="nav-box"
        onClick={() => handleNavClick('/battle/battle-list')}
      >
        <div className="bl-color-block battle-blue_bg nav-border-radius"></div>
        <div className="nav-text">
          <h2>bl</h2>
          <p>battle List</p>
        </div>
      </div>
      <div
        className="nav-box"
        onClick={() => handleNavClick('/battle/battle-create')}
      >
        <div className="bl-color-block battle-purple_bg nav-border-radius"></div>
        <div className="nav-text">
          <h2>bc</h2>
          <p>battle create</p>
        </div>
      </div>
      <div className="nav-box" onClick={() => handleNavClick('/user/profile')}>
        <div className="bl-color-block battle-dark-purple_bg nav-border-radius"></div>
        <div className="nav-text">
          <h2>up</h2>
          <p>user profiles</p>
        </div>
      </div>
      <div
        className="nav-box"
        onClick={() => handleNavClick('/user/champion-list')}
      >
        <div className="bl-color-block battle-orange_bg nav-border-radius"></div>
        <div className="nav-text">
          <h2>cl</h2>
          <p>champion list</p>
        </div>
      </div>
    </div>
  );
};
