import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Headline } from '../_shared/components/headline.component'
import { Button } from '../_shared/components/button.component'
import './sign-in.styles.scss';

type Inputs = {
  userName: string;
  password: string;
};

export const SignIn = () => {
  const { register, handleSubmit, watch, errors } = useForm<Inputs>();
  const onSubmit = (data: any): void => {
    if (data.userName === 'User name') {
      console.log('get out of here with that')
    }
  }
  console.log(watch('userName'));
  console.log(watch('password'));

  return (
    <div className="form-container center">
      <Headline text="step up. let's do this." />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="userName"
          placeholder="user name"
          ref={register({ required: true })}
        />
        {errors.userName && <span>This field is required</span>}
        <input
          name="password"
          placeholder="pssword"
          ref={register({ required: true })}
        />
        {errors.password && <span>This field is required</span>}
        <input type="submit" value="sign in" className="btn-primary" />
      </form>
      <div className="text-left mt-1-2">
        <Link to={`/user/account-create`}>
          <Button text="create an account" buttonStyle="text-link" />
        </Link>
      </div>
    </div>
  );
};

