import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { SignIn } from './signIn/sign-in.component';
import { BattleList } from './battle/battle-list.component';
import { BattleCreate } from './battle/battle-create.component';
import { UserProfileList } from './user/user-profile-list.component';
import { ChampionList } from './champion/champion-list.component';
import { AccountCreate } from './account-create/account-create.component';
import { SiteNav } from './navigation/site-nav.component';
import './app.styles.scss';

function App() {
  return (
    <div className="App grid-layout">
      <BrowserRouter>
        <SiteNav />
        <div className="main-content">
          <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/user/account-create" component={AccountCreate} />
            <Route path="/battle/battle-list" component={BattleList} />
            <Route path="/battle/battle-create" component={BattleCreate} />
            <Route path="/user/user-profiles" component={UserProfileList} />
            <Route path="/user/champion-list" component={ChampionList} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
